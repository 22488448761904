import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Search from "../components/search";

const FundListCaret = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline h-5 w-5 text-gray-400 align-text-bottom"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const FundList = ({ fundList }) => {
  return (
    <div className="bg-white border border-gray-300 rounded-md m-1" key={1}>
      <div className="divide-y divide-gray-300">
        {fundList.map((value, index) => {
          return (
            <div key={index + 1} className="hover:bg-gray-100">
              <Link to={`/fund/${value}`}>
                <p className="px-4 py-2 flex">
                  <span className="flex-auto">
                    {index + 1}. {value}
                  </span>
                  <span className="flex-intial">
                    <FundListCaret />
                  </span>
                </p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="" />
      <div className="container mx-auto px-4 max-w-screen-sm">
        <section className="text-center my-8">
          <h1 className="text-gray-600 text-2xl">
            ตัวช่วยค้นหาข้อมูล <br className="sm:hidden" />
            กองทุนต่างประเทศ
          </h1>
        </section>
        <section className="mt-4">
          <Search />
        </section>
        <section className="mt-12 mx-auto text-gray-500 max-w-sm md:max-w-none grid grid-cols-1 md:grid-cols-2 md:gap-x-10">
          <div>
            <h3 className="mx-4 md:mx-1 my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline align-text-top h-5 w-5 text-yellow-500 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              กองทุนที่มีผู้เข้าชมมากสุดในรอบเดือน
            </h3>
            <FundList
              fundList={[
                "TMBGQG",
                "SCBPGF",
                "B-INNOTECH",
                "ONE-UGG-RA",
                "K-CHINA-A(A)",
              ]}
            />
          </div>
          <div className="mt-5 md:mt-0">
            <h3 className="mx-4 md:mx-1 my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline align-text-top h-5 w-5 text-green-500 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M5 3a1 1 0 000 2c5.523 0 10 4.477 10 10a1 1 0 102 0C17 8.373 11.627 3 5 3z" />
                <path d="M4 9a1 1 0 011-1 7 7 0 017 7 1 1 0 11-2 0 5 5 0 00-5-5 1 1 0 01-1-1zM3 15a2 2 0 114 0 2 2 0 01-4 0z" />
              </svg>
              กองทุนอัพเดทข้อมูลล่าสุด
            </h3>
            <FundList
              fundList={[
                "ES-INDONESIA",
                "SCBGVALUE(A)",
                "MINFRA",
                "UBOT",
                "SCBGQUAL(A)",
              ]}
            />
          </div>
        </section>
        <section className="mt-8 mx-2 text-gray-500">
          <p>
            การหาข้อมูลกองทุนที่ลงทุนต่างประเทศ ไม่ใช่เรื่องยากอีกต่อไป{" "}
            <br className="hidden sm:inline"></br>
            กองแม่.com รวบรวมแหล่งข้อมูลกองทุนต่างประเทศ
            ให้ศึกษาและติดตามได้อย่างง่ายดาย{" "}
            <br className="hidden sm:inline "></br>
            <Link to="/about" className="underline ml-2 sm:ml-0 text-sm">
              อ่านต่อ
            </Link>
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
